<form [formGroup]="form">
  <div class="d-flex flex-column mb-3">
    <label for="dateInput"
           class="col-form-label fs-6 fw-bolder"><span i18n="@@app.input.date">Date</span> *</label>
    <input type="datetime-local"
           class="form-control"
           id="dateInput"
           formControlName="dateControl"
           [max]="maxDay"
           [class.is-invalid]="dateControl?.invalid && dateControl?.touched"
           required />
  </div>

  <div class="d-flex flex-column">
    <div class="d-flex flex-column flex-fill mb-3 me-3">
      <label for="bloodGlucoseInput"
             class="col-form-label fs-6 fw-bolder"><span i18n="@@app.input.blood-glucose">Blood Glucose (mmol/L)</span>
        *</label>
      <input id="bloodGlucoseInput"
             type="text"
             inputmode="decimal"
             formControlName="bloodGlucoseControl"
             class="form-control"
             (input)="sanitizeBloodGlucoseControlInput($event.target)"
             [class.is-invalid]="bloodGlucoseControl?.invalid && bloodGlucoseControl?.touched"
             required />
    </div>

    <div class="d-flex flex-column mb-3">
      <label for="codeInput"
             class="col-form-label fs-6 fw-bolder"><span i18n="@@app.input.blood-glucose.code">Code</span>
        *</label>
      <select id="codeInput"
              class="form-control form-select"
              formControlName="codeControl"
              required>
        <option value=""
                selected><span i18n="@@app.input.select">- select -</span></option>
        <option *ngFor="let coding of translatedKledingCodelijst"
                [value]="coding.code">{{ coding.display }}</option>
      </select>
    </div>
  </div>

  <div class="d-flex flex-column border border-3 border-gray-300 rounded p-2">
    <div class="d-flex flex-column mb-3 col-form-label fs-6 fw-bolder">
      <span i18n="@@app.input.optional-additional-information">Optional Additional Information</span>
    </div>

    <div class="d-flex flex-column mb-3">
      <label for="timingInput"
             class="col-form-label fs-6 fw-bolder"><span i18n="@@app.input.blood-glucose.timing">Timing</span>
      </label>
      <select id="timingInput"
              class="form-control form-select"
              formControlName="timingControl">
        <option value=""
                selected><span i18n="@@app.input.select">- select -</span></option>
        <option *ngFor="let timing of translatedTimingList"
                [value]="timing.code">{{ timing.display }}</option>
      </select>
    </div>

    <div class="d-flex flex-column mb-3">
      <label for="additionalCommentsInput"
             class="col-form-label fs-6 fw-bolder"><span i18n="@@app.input.additionalComment">Additional Comments</span>
      </label>
      <textarea type="text"
                class="form-control text-truncate textarea-scroll"
                formControlName="additionalCommentsControl"
                maxlength="300"
                pattern="^[\s\S]+$"
                id="additionalCommentsInput"
                rows="2"></textarea>
    </div>
  </div>
</form>
