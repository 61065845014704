import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { limitAndFormatDecimal, nameof } from '@globals';
import { Coding } from '@hl7fhir';
import { ValueSets } from '@hl7fhir/value-sets';
import { JaNeeCodelijst } from '@hl7nl-fhir/value-sets';
import { ButtonComponent } from '@layout';
import { SharedModule } from '@shared';

@Component({
  standalone: true,
  selector: 'app-blood-oxygen-entry',
  templateUrl: './blood-oxygen-entry.component.html',
  imports: [SharedModule, ButtonComponent],
})
export class BloodOxygenEntryComponent {
  public static jaNeeCodelijstCodings: Coding[] = [
    { system: 'http://hl7.org/fhir/v2/0136', code: 'Y', display: 'Yes' },
    { system: 'http://hl7.org/fhir/v2/0136', code: 'N', display: 'No' },
  ];

  @Input({ required: true }) form!: FormGroup;

  @Output() submitForm: EventEmitter<void> = new EventEmitter<void>();

  readonly jaNeeCodelijst = JaNeeCodelijst;
  readonly translatedJaNeeCodelijst: Coding[];

  constructor() {
    this.translatedJaNeeCodelijst = BloodOxygenEntryComponent.jaNeeCodelijstCodings.map((coding) => {
      const display = ValueSets.getDisplay(coding, [this.jaNeeCodelijst]);
      return { ...coding, display: display.code } as Coding;
    });
  }

  get maxDay(): string {
    const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    const localISOString = new Date(Date.now() - tzoffset).toISOString();
    const dateString = localISOString.substring(0, 16);

    return dateString;
  }

  get dateControl() {
    return this.form.get(nameof<BloodOxygenEntryComponent>('dateControl'));
  }

  get bloodOxygenControl(): any {
    return this.form.get(nameof<BloodOxygenEntryComponent>('bloodOxygenControl'));
  }

  get oxygenAdministeredControl(): any {
    return this.form.get(nameof<BloodOxygenEntryComponent>('oxygenAdministeredControl'));
  }

  /**
   * Limits the input value of a target element to a maximum available number and a specific decimal pattern.
   * @param target - The target element that triggered the event.
   */
  sanitizeBloodOxygenControlInput(target: EventTarget | null): void {
    const input = target as HTMLInputElement;
    const value: string | number = limitAndFormatDecimal(input.value, 100, 0);
    this.bloodOxygenControl!.setValue(value);
  }
}
