import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { limitAndFormatDecimal, nameof } from '@globals';
import { Coding } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';
import { ValueSets } from '@hl7fhir/value-sets';
import { GlucoseMeetmomentenLOINCCodelijst } from '@hl7nl-fhir/codesystems';
import { SharedModule } from '@shared';

@Component({
  standalone: true,
  selector: 'app-blood-glucose-entry',
  templateUrl: './blood-glucose-entry.component.html',
  imports: [SharedModule],
})
export class BloodGlucoseEntryComponent {
  public static glucoseCodelijstCodings: Coding[] = [
    { system: 'http://loinc.org', code: '14770-2', display: 'Blood Glucose: fasting' },
    { system: 'http://loinc.org', code: '14743-9', display: 'Blood Glucose: non fasting' },
    { system: 'http://loinc.org', code: '14760-3', display: 'Blood Glucose: 2 hours post-meal' },
  ];

  public static timingEventCodelijstCodings: Coding[] = [
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'AC', display: 'Before meal' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'ACD', display: 'Before lunch' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'ACM', display: 'Before breakfast' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'ACV', display: 'Before dinner' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'C', display: 'Meal' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'HS', display: 'Prior beginning sleep' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'IC', display: 'Between meals' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'ICD', display: 'Between lunch and dinner' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'ICM', display: 'Between breakfast and lunch' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'ICV', display: 'Between dinner and the hour of sleep' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'PC', display: 'After meal' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'PCD', display: 'After lunch' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'PCM', display: 'After breakfast' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'PCV', display: 'After dinner' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'WAKE', display: 'WAKE' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'CD', display: 'Lunch' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'CM', display: 'Breakfast' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'CV', display: 'Dinner' },
  ];

  @Input({ required: true }) form!: FormGroup;

  @Output() submitForm: EventEmitter<void> = new EventEmitter<void>();

  readonly glucoseMeetmomentenLOINCCodelijst = GlucoseMeetmomentenLOINCCodelijst;
  readonly translatedKledingCodelijst: Coding[];
  readonly translatedTimingList: Coding[];

  constructor() {
    this.translatedKledingCodelijst = BloodGlucoseEntryComponent.glucoseCodelijstCodings.map((coding) => {
      const display = ValueSets.getDisplay(coding, [this.glucoseMeetmomentenLOINCCodelijst]);
      return { ...coding, display: display.code } as Coding;
    });
    this.translatedTimingList = BloodGlucoseEntryComponent.timingEventCodelijstCodings.map((coding) => {
      const display = CodeSystems.getDisplay(coding);
      return { ...coding, display } as Coding;
    });
  }

  get maxDay(): string {
    const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOString = new Date(Date.now() - tzoffset).toISOString();
    const dateString = localISOString.substring(0, 16);

    return dateString;
  }

  get dateControl() {
    return this.form.get(nameof<BloodGlucoseEntryComponent>('dateControl'));
  }

  get bloodGlucoseControl() {
    return this.form.get(nameof<BloodGlucoseEntryComponent>('bloodGlucoseControl'));
  }

  get codeControl() {
    return this.form.get(nameof<BloodGlucoseEntryComponent>('codeControl'));
  }

  get timingControl() {
    return this.form.get(nameof<BloodGlucoseEntryComponent>('timingControl'));
  }

  /**
   * Limits the input value of a target element to a maximum available number and a specific decimal pattern.
   * @param target - The target element that triggered the event.
   */
  sanitizeBloodGlucoseControlInput(target: EventTarget | null): void {
    const input = target as HTMLInputElement;
    const value: string | number = limitAndFormatDecimal(input.value, 999.0, 0.0);
    this.bloodGlucoseControl!.setValue(value);
  }
}
