import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getMaxDay, limitAndFormatDecimal, nameof } from '@globals';
import { Coding } from '@hl7fhir';
import { ValueSets } from '@hl7fhir/value-sets';
import { ButtonComponent } from '@layout';
import { SharedModule } from '@shared';
import { KLEDING_CODELIJST } from 'src/app/fhir/fhir-nl/codelijsten/kleding-codelijst';

@Component({
  standalone: true,
  selector: 'app-body-weight-entry',
  templateUrl: './body-weight-entry.component.html',
  imports: [SharedModule, ButtonComponent],
})
export class BodyWeightEntryComponent {
  public static kledingCodelijstCodings: Coding[] = [
    { system: 'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4.8.1', code: 'UNDRESSED', display: 'Undressed' },
    { system: 'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4.8.1', code: 'MINIMAL', display: 'Minimal dressed' },
    { system: 'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4.8.1', code: 'FULL', display: 'Fully dressed' },
    { system: 'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4.8.1', code: 'DIAPER', display: 'Diaper' },
  ];

  @Input({ required: true }) form!: FormGroup;

  @Output() submitForm: EventEmitter<void> = new EventEmitter<void>();

  readonly kledingCodelijst = KLEDING_CODELIJST;
  readonly translatedKledingCodelijst: Coding[];

  constructor() {
    this.translatedKledingCodelijst = BodyWeightEntryComponent.kledingCodelijstCodings.map((coding) => {
      const display = ValueSets.getDisplay(coding, [KLEDING_CODELIJST]);
      return { ...coding, display: display.code } as Coding;
    });
  }

  get maxDay(): string {
    return getMaxDay();
  }

  get dateControl() {
    return this.form.get(nameof<BodyWeightEntryComponent>('dateControl'));
  }

  get unitControl() {
    return this.form.get(nameof<BodyWeightEntryComponent>('unitControl'));
  }

  get weightControl() {
    return this.form.get(nameof<BodyWeightEntryComponent>('weightControl'));
  }

  /**
   * Limits the input value of a target element to a maximum available number and a specific decimal pattern.
   * @param target - The target element that triggered the event.
   */
  sanitizeBodyWeightControlInput(target: EventTarget | null): void {
    const input = target as HTMLInputElement;
    const value: string | number = limitAndFormatDecimal(input.value, 999_999.99, 0.01);
    this.weightControl!.setValue(value);
  }
}
